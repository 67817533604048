import { v4 as uuidv4 } from 'uuid';

import { SelectOption } from 'types';
import { Package } from 'types/interfaces/payment/Package';
import { SubscriptionInfo } from 'types/interfaces/payment/SubscriptionInfo';
import { AuthUser } from 'types/interfaces/user/AuthUser';
import { User } from 'types/interfaces/user/User';

import { getNetworkParams } from 'helpers/network';

import { httpClient } from './httpClient';

export interface AccessTokenData {
  access_token: string;
  expires_in: number;
}

export interface InitResponse {
  credit_pays: {
    count_of_pays: number;
  };
  credits: {
    amount: number;
    currency: string;
  };
  unread_counters?: {
    dialogs: number;
    inmails: number;
    notifications: number;
    chat_requests: number;
  };
  payment: {
    packages: Package[];
    gift_packages: Package[];
    recurring_packages: Package[];
  };
  refill: { refill_enabled: boolean; refill_package: string | null };
  site_language: {
    options: string[];
    value: string;
  };
  features: {
    SAY_HELLO: number;
    CHAT_REQUEST: number;
    PWA_ON_MAIL_CONFIRM: number;
    NEW_PAYMENT_FORM: number;
  };
  sound_notification: boolean;
  subscription: SubscriptionInfo;
  theme: PackagesThemeGroups;
  translation: {
    enabled: boolean;
    build_id: string;
  };
  user: AuthUser;
  front_track: boolean;
  wizard_finish: boolean;
}

export type RegisterPayload = {
  email: string;
  password: string;
  gender: number;
  age: number;
  name?: string;
  city?: number;
  city_id?: number;
  preland: {
    id: number;
    screen_number: number;
    history_length: number | null;
    params?: Record<
      string,
      string | number | boolean | SelectOption[] | undefined
    >;
  };
};

export type RegisterResponse = {
  user?: User;
} & AccessTokenData;

export enum OnlineType {
  RealOnline = 1,
  ForceOnline = 2,
  ForceOffline = 3,
  RealOffline = 4,
}

export interface AutoRegisterPayload {
  [key: string]: string | (string | null)[] | null;
}

interface LoginPayload {
  email: string;
  password: string;
}

export enum PackagesThemeGroups {
  Default = 'default',
  FreeTrial = 'trial-group-2',
}

export const AuthApi = {
  async init({ from }: { from: string }): Promise<InitResponse> {
    const initLoadStart = Date.now();
    const initId = uuidv4();

    const { data } = await httpClient.get<InitResponse>('/init', {
      params: {
        id: window?.appLoadId,
        from: from || 'ref',
        ft: initLoadStart,
        start_at: initLoadStart,
        url: window.location.href,
        ...getNetworkParams(),
      },
    });

    this.initLoadEnd({ initLoadStart, initId });

    return data;
  },

  initLoadEnd({
    initLoadStart,
    initId,
  }: {
    initLoadStart: number;
    initId: string;
    userId?: string;
  }) {
    const initLoadEndAt = Date.now();

    return httpClient.post('/track/init-load-end', {
      ...getNetworkParams(),
      id: initId,
      end_at: initLoadEndAt,
      duration: initLoadEndAt - initLoadStart,
      url: window.location.href,
    });
  },

  async register(payload: RegisterPayload) {
    const { data } = await httpClient.post<RegisterResponse>('/register', {
      ...payload,
      app_load_id: window?.appLoadId,
    });

    return data;
  },

  async login(payload: LoginPayload) {
    const { data } = await httpClient.post<AccessTokenData>('login', {
      ...payload,
      app_load_id: window?.appLoadId,
    });

    return data;
  },

  async logout() {
    return httpClient.get('/logout');
  },

  async checkEmail({ email }: { email: string }) {
    await httpClient.post('/register/check-email', { email, unique: email });
  },

  async checkCode({ code }: { code: string }) {
    await httpClient.get(`/recovery/check-code/${code}`);
  },

  async sendVerificationCode({ email }: { email: string }) {
    await httpClient.post('/recovery/send-mail', { email });
  },

  async changePassword({ password, code }: { password: string; code: string }) {
    const { data } = await httpClient.post<AccessTokenData>(
      `/recovery/change-password/${code}`,
      {
        password,
      }
    );

    return data;
  },

  async pingOnline(siteVersion?: string | null) {
    const { data } = await httpClient.get('/ping', {
      headers: {
        ...(siteVersion && { 'X-Frontend-Version': siteVersion }),
      },
    });

    return data;
  },

  async pingOnlineV2(type: OnlineType) {
    const { data } = await httpClient.post('/ping/v2', { type });

    return data;
  },

  async autoLogin({ loginToken }: { loginToken: string }) {
    const { data } = await httpClient.post<AccessTokenData>('/auto-login', {
      token: loginToken,
      app_load_id: window?.appLoadId,
    });

    return data;
  },

  async getSiteVersion() {
    try {
      const response = await fetch(`/version.txt?timestamp=${Date.now()}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (response.status === 200) return await response?.text();

      return null;
    } catch {
      return null;
    }
  },
};
