import { createSelector } from '@reduxjs/toolkit';

import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';

import { getUserEmailSubscriptionStatus } from 'store/auth/selectors';
import { RootState } from 'store/rootReducer';

export const getNewChatRequestCount = (state: RootState) =>
  state.common.unreadCount.chat_requests;

export const getNewDialogsCount = (state: RootState) =>
  state.common.unreadCount.dialogs;

export const getNewNotificationsCount = (state: RootState) =>
  state.common.unreadCount.notifications;

export const getNewMailsChainsCount = (state: RootState) =>
  state.common.unreadCount.inmails;

export const getIsCommonStateLoading = (state: RootState) =>
  state.common.loading;

export const getPrevPagePathname = (state: RootState) =>
  state.common.prevPagePathname;

export const getCurrentLangSelector = (state: RootState) =>
  state.common.currentLang;

export const getSupportedLangsSelector = (state: RootState) =>
  state.common.supportedLangs;

export const isTranslationsEnabledSelector = (state: RootState) =>
  state.common.translations.isEnabled;

export const getInitializedPixelsIds = (state: RootState) =>
  state.common.initializedPixelsIds;

export const getIsConfirmationProfilePopupShownSelector = (state: RootState) =>
  state.common.popups.confirmMailFromProfilePopup;

export const getIsConfirmationNotificationShownSelector = (
  state: RootState
) => {
  return state.common.popups.confirmMailFromNotificationPopup;
};

export const getFeatureFlagsSelector = (state: RootState) =>
  state.common.featureFlags;

export const getIsEnabledNotificationsSoundSelector = createSelector(
  getFeatureFlagsSelector,
  (featureFlags) => featureFlags.isEnabledNotificationsSound
);

export const getIsEnabledMailConfirmAfterRegSelector = createSelector(
  getUserEmailSubscriptionStatus,
  (userEmailSubscriptionStatus) =>
    userEmailSubscriptionStatus === EmailSubscriptionStatus.ConfirmationSend
);

export const getIsEnabledChatSayHelloInitSplitSelector = createSelector(
  getFeatureFlagsSelector,
  (featureFlags) => featureFlags.isEnabledChatSayHelloInit
);

export const getIsEnabledIntentFormSelector = createSelector(
  getFeatureFlagsSelector,
  (featureFlags) => featureFlags.isEnabledIntentForm
);

export const getIsEnabledChatRequestsSelector = createSelector(
  getFeatureFlagsSelector,
  (featureFlags) => featureFlags.isEnabledChatRequests
);

export const getIsWizardFinishedSelector = (state: RootState) =>
  state.common.isWizardFinished;

export const getIsEnabledPWASystemPopupSelector = createSelector(
  getFeatureFlagsSelector,
  (featureFlags) => featureFlags.isEnabledPWASystemPopup
);
