import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ChatMessageStatus } from 'types/enums/chat/ChatMessageStatus';
import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';
import { MailStatus } from 'types/enums/mails/MailStatus';
import { MediaType } from 'types/enums/MediaType';
import { NotificationTypes } from 'types/enums/NotificationTypes';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { Mail } from 'types/interfaces/mails/Mail';
import { INotification } from 'types/interfaces/Notifications';
import { PresentTrackInfo } from 'types/interfaces/presents/PresentTrackInfo';
import { UnreadCounters as IUnreadCounters } from 'types/interfaces/UnreadCounters';
import { UserContact } from 'types/interfaces/user/UserContact';

import { TrackingApi } from 'api/TrackingApi';
import { Funnel } from 'helpers/funnels/funnels';
import { isDialogPage, isDialogsPage, isMailsPage } from 'helpers/route';
import { assertNumber } from 'helpers/utils/checkType';
import { updateUser } from 'store/auth/authSlice';
import { updateUnreadCounters } from 'store/common/commonSlice';
import { getIsEnabledNotificationsSoundSelector } from 'store/common/selectors';
import { addNewMailFromSocketThunk } from 'store/mails/thunks';
import {
  addTypingContact,
  markMessagesAsRead,
  removeChatRequest,
  removeInitTypingContact,
  removeTypingContact,
  updateDialogStatus,
  updateLatMessagesIncomingStatus,
} from 'store/messenger/messengerSlice';
import { addNewMessageFromSocketThunk } from 'store/messenger/thunks';
import { addNewNotification } from 'store/notifications/notificationsSlice';
import { readNotificationThunk } from 'store/notifications/thunks';
import { setCreditsAmount } from 'store/payment/paymentSlice';
import {
  setContactMediaAccesses,
  updateContactPresent,
} from 'store/profile/profileSlice';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

import notificationSound from 'assets/audio/notification.mp3';

import { useConversionTracking } from './useConversionTracking';
import { useToast } from './useToast';

const CONTACT_TYPING_INTERVAL = 4000;

enum WsActionTypes {
  // * messageHandler
  Send = 'send',
  Read = 'read',
  Fail = 'fail',
  Typing = 'typing',

  // * notificationsHandler
  CreditBalanceUpdate = 'credit_balance',
  PhotoModerationFail = 'photo_moderation_fail',
  NewNotification = 'new_notification',
  RepeatNotification = 'repeat_notification',

  // * mailsHandler
  InMailSend = 'inmail_send',
  InMailSendFail = 'inmail_send_fail',
  InMailRead = 'inmail_read',
  MailSubscriptionStatus = 'subscription_status',

  // * systemHandler
  UnreadCounters = 'unread_counters_updated',

  // * presentsHandler
  PresentStatusChange = 'real_gift_process_status_changed',
}

const audio = new Audio(notificationSound);

export const useWebSocketHandlers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const typingContactsTimeouts = useRef<Record<string, NodeJS.Timeout>>({});

  const { pathname } = useLocation();

  const { showNotificationToast, showWarningToast } = useToast();

  const { conversionEventHandler } = useConversionTracking();

  const isEnabledNotificationsSound = useSelector(
    getIsEnabledNotificationsSoundSelector
  );

  const getNotificationToastBtnText = useCallback(
    (notificationType: NotificationTypes) => {
      switch (notificationType) {
        case NotificationTypes.Inbox:
          return 'View mail';
        case NotificationTypes.Message:
          return 'Reply';
        case NotificationTypes.Like:
        case NotificationTypes.Visit:
        case NotificationTypes.MutualLike:
          return 'View profile';

        default:
          return '';
      }
    },
    []
  );

  const messageHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) return;

      switch (action) {
        case WsActionTypes.Send: {
          const {
            contact,
            message: newMessage,
            front_message_id,
          } = event.data as {
            contact: UserContact;
            message: ChatMessage;
            front_message_id?: string;
          };

          if (!contact || !newMessage) return;

          dispatch(
            addNewMessageFromSocketThunk({
              message:
                !newMessage.is_incoming && front_message_id
                  ? { ...newMessage, front_message_id }
                  : newMessage,
              contactId: contact.ulid_id,
            })
          );

          dispatch(
            updateLatMessagesIncomingStatus({
              contactId: contact.ulid_id,
              isIncoming: newMessage?.is_incoming ? 1 : 0,
            })
          );

          if (newMessage?.is_incoming) {
            dispatch(removeTypingContact({ contactId: contact.ulid_id }));
            dispatch(removeInitTypingContact({ contactId: contact.ulid_id }));

            if (newMessage.id && !newMessage.request_id)
              TrackingApi.trackMessageReceived({
                messageId: newMessage.id,
              });

            if (newMessage.request_id)
              TrackingApi.trackRequestReceived({
                requestId: newMessage.request_id,
              });
          }

          if (!newMessage?.is_incoming) {
            dispatch(removeChatRequest({ contactId: contact.ulid_id }));

            if (newMessage.id && !newMessage.request_id)
              TrackingApi.trackMessageSent({
                messageId: newMessage.id,
              });

            if (newMessage.request_id)
              TrackingApi.trackRequestSent({
                requestId: newMessage.request_id,
              });
          }

          if (
            isEnabledNotificationsSound &&
            !isDialogsPage(pathname) &&
            newMessage?.is_incoming
          ) {
            audio?.play()?.catch(() => {});
          }

          break;
        }

        case WsActionTypes.Fail: {
          const {
            contact,
            message: newMessage,
            front_message_id,
          } = event.data as {
            contact: UserContact;
            message: ChatMessage;
            front_message_id?: string;
          };

          if (!contact || !newMessage) return;

          dispatch(
            addNewMessageFromSocketThunk({
              message:
                !newMessage.is_incoming && front_message_id
                  ? { ...newMessage, front_message_id }
                  : newMessage,
              contactId: contact.ulid_id,
            })
          );

          if (!newMessage?.is_incoming) {
            dispatch(removeChatRequest({ contactId: contact.ulid_id }));

            dispatch(removeChatRequest({ contactId: contact.ulid_id }));

            if (newMessage.id && !newMessage.request_id)
              TrackingApi.trackMessageSent({
                messageId: newMessage.id,
              });

            if (newMessage.request_id)
              TrackingApi.trackRequestSent({
                requestId: newMessage.request_id,
              });
          }

          if (
            isDialogPage(pathname) &&
            newMessage.status === ChatMessageStatus.Failed
          ) {
            dispatch(
              setActiveSystemPopup({
                type: SystemPopupTypes.LowBalance,
                params: {
                  name: contact.name || 'User',
                  photoUrl: contact?.main_photo?.big_url,
                  url: `/dialogs/${contact.ulid_id}`,
                  funnel: Funnel.Message,
                },
              })
            );
          }

          break;
        }

        case WsActionTypes.Read: {
          const contactId = event?.data?.contact;

          if (!contactId) return;

          if (!event?.data?.incoming)
            dispatch(markMessagesAsRead({ contactId }));

          dispatch(
            updateDialogStatus({ contactId, isIncoming: event?.data?.incoming })
          );

          break;
        }

        case WsActionTypes.Typing: {
          const contactId = event?.data?.contact as string;

          if (!contactId) return;

          dispatch(addTypingContact({ contactId }));

          if (typingContactsTimeouts.current[contactId]) {
            clearTimeout(typingContactsTimeouts.current[contactId]);
          }

          typingContactsTimeouts.current[contactId] = setTimeout(() => {
            dispatch(removeTypingContact({ contactId }));
            dispatch(removeInitTypingContact({ contactId }));
          }, CONTACT_TYPING_INTERVAL);

          break;
        }

        default:
          break;
      }
    },
    [dispatch, isEnabledNotificationsSound, pathname]
  );

  const notificationsHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) return;

      switch (action) {
        case WsActionTypes.CreditBalanceUpdate: {
          const { balance: incomeBalance } = event.data;

          if (!assertNumber(incomeBalance)) return;

          dispatch(setCreditsAmount(incomeBalance));

          break;
        }

        case WsActionTypes.PhotoModerationFail: {
          const { message: failMessage } = event.data;

          if (!failMessage) return;

          showWarningToast({
            title: failMessage,
            onClick: () => {
              history.push('/my-profile/edit');
            },
          });

          break;
        }

        case WsActionTypes.NewNotification:
        case WsActionTypes.RepeatNotification: {
          const notification = event.data?.notification as INotification;

          if (!notification?.title || !notification?.description) return;

          if (action === WsActionTypes.NewNotification) {
            dispatch(addNewNotification(notification));
          }

          if (
            (notification.type === NotificationTypes.Message ||
              notification.type === NotificationTypes.Inbox) &&
            notification?.sender?.ulid_id &&
            pathname?.endsWith(notification?.sender?.ulid_id)
          )
            return;

          showNotificationToast({
            btnText: getNotificationToastBtnText(notification.type),
            notification,
            onClick: () => {
              dispatch(
                readNotificationThunk({ notificationId: notification.id })
              );

              if (!notification.sender) return;

              if (
                notification?.type === NotificationTypes.Like ||
                notification?.type === NotificationTypes.Visit ||
                notification?.type === NotificationTypes.MutualLike
              ) {
                history.push(`/user/${notification.sender.ulid_id}`);

                return;
              }

              if (notification?.type === NotificationTypes.Inbox) {
                history.push(`/mails/${notification.sender.ulid_id}`);

                return;
              }

              history.push(`/dialogs/${notification.sender.ulid_id}`);
            },
          });

          break;
        }

        case WsActionTypes.MailSubscriptionStatus: {
          const { subscription_status: subscriptionEmailStatus } =
            event.data as {
              subscription_status: EmailSubscriptionStatus;
            };

          if (!subscriptionEmailStatus) return;

          dispatch(
            updateUser({ subscription_email_status: subscriptionEmailStatus })
          );

          break;
        }

        default:
          break;
      }
    },
    [
      dispatch,
      getNotificationToastBtnText,
      history,
      pathname,
      showNotificationToast,
      showWarningToast,
    ]
  );

  const mailsHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) {
        return;
      }

      // eslint-disable-next-line sonarjs/no-small-switch
      switch (action) {
        case WsActionTypes.InMailSend:
          {
            const { contact, inmail: newInMails } = event.data as {
              contact: UserContact;
              inmail: Mail;
            };

            if (!contact || !newInMails) return;

            dispatch(
              addNewMailFromSocketThunk({
                message: newInMails,
                contactId: contact.ulid_id,
              })
            );
          }
          break;

        case WsActionTypes.InMailSendFail: {
          const { contact, inmail: newInMails } = event.data as {
            contact: UserContact;
            inmail: Mail;
          };

          if (!contact || !newInMails) return;

          dispatch(
            addNewMailFromSocketThunk({
              message: newInMails,
              contactId: contact.ulid_id,
            })
          );

          if (
            newInMails.status === MailStatus.Failed &&
            isMailsPage(pathname)
          ) {
            dispatch(
              setActiveSystemPopup({
                type: SystemPopupTypes.LowBalance,
                params: {
                  name: contact.name || 'User',
                  photoUrl: contact?.main_photo?.big_url,
                  url: `/mails/${contact.ulid_id}`,
                  funnel: Funnel.MailSend,
                },
              })
            );
          }

          break;
        }

        default:
          break;
      }
    },
    [dispatch, pathname]
  );

  const conversionHandler = useCallback(
    (event: any) => {
      conversionEventHandler(event);
    },
    [conversionEventHandler]
  );

  const systemHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) {
        return;
      }

      if (action === WsActionTypes.UnreadCounters) {
        const unreadCounters = event.data?.unread_counters as IUnreadCounters;

        if (!unreadCounters) return;

        dispatch(updateUnreadCounters(unreadCounters));
      }
    },
    [dispatch]
  );

  const mediaAccessHandler = useCallback(
    (event: any) => {
      const data = event?.data;

      if (!data) {
        return;
      }

      const { owner, accesses, mass_accesses } = event.data as {
        owner: string;
        accesses: Record<MediaType, string[]>;
        mass_accesses: MediaType[];
      };

      if (!owner || !accesses || !mass_accesses) return;

      dispatch(
        setContactMediaAccesses({
          contactId: owner,
          accesses,
          accessesByMediaType: mass_accesses,
        })
      );
    },
    [dispatch]
  );

  const presentsHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) return;

      if (action === WsActionTypes.PresentStatusChange) {
        const presentInfo = event.data?.real_gift_process as PresentTrackInfo;

        if (!presentInfo || !presentInfo.id) return;

        dispatch(updateContactPresent(presentInfo));
      }
    },
    [dispatch]
  );

  return {
    messageHandler,
    notificationsHandler,
    mailsHandler,
    conversionHandler,
    systemHandler,
    mediaAccessHandler,
    presentsHandler,
  };
};
