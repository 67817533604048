import { CreditCard } from 'types/interfaces/payment/CreditCard';
import { Order } from 'types/interfaces/payment/Order';

import { httpClient } from './httpClient';

export interface PCIPaymentFormPayload {
  packageId: string;
}

export interface PCIPaymentFormResponse {
  url: string;
  order_id: string;
}

export interface IntentPaymentFormPayload {
  packageId: string;
}

export interface IntentPaymentFormResponse {
  intent_data: {
    merchant: string;
    paymentIntent: string;
    signature: string;
  };
  order_id: string;
}

export interface OneClickRecurringPayload {
  cardToken: string;
  packId: string;
  isRefilled?: boolean;
}
export interface OneClickResignPayload extends OneClickRecurringPayload {
  cvv: string;
}
export interface OneClickPaymentResponse {
  credits: number | null;
  order: Order;
  verify_url: string | null;
  offer_url: string | null;
  error: string | null;
  error_title: string | null;
  error_code: number | null;
}

export const PaymentApi = {
  async fetchPCIPaymentForm(payload: PCIPaymentFormPayload) {
    const { data } = await httpClient.post<PCIPaymentFormResponse>(
      '/payment/credit/form',
      {
        package_info: payload.packageId,
      }
    );

    return data;
  },

  async fetchIntentPaymentForm(payload: IntentPaymentFormPayload) {
    const { data } = await httpClient.post<IntentPaymentFormResponse>(
      '/payment/credit/intent',
      {
        package_info: payload.packageId,
      }
    );

    return data;
  },

  async fetchCreditCards() {
    return httpClient.get<{
      cards: CreditCard[];
      last_success_card_id: string | null;
    }>('/payment/card/list');
  },

  async oneClickCreditsRecurring(payload: OneClickRecurringPayload) {
    const { data } = await httpClient.post<OneClickPaymentResponse>(
      '/payment/credit/recurring',
      {
        card: payload.cardToken,
        package_info: payload.packId,
        is_refilled: payload.isRefilled,
      }
    );

    return data;
  },

  async changeRefillStatus(isRefill: boolean) {
    const { data } = await httpClient.post('/credit/refill/change-status', {
      is_refill: isRefill ? 1 : 0,
    });

    return data;
  },

  async changeRefillPackage(packageId: string) {
    const { data } = await httpClient.post('credit/refill/change', {
      package_id: packageId,
    });

    return data;
  },

  async updateUpSalePackage({
    orderId,
    upSalePackageIds,
  }: {
    orderId: string;
    upSalePackageIds: string[] | null;
  }) {
    return httpClient.patch('/payment/credit/extra', {
      order: orderId,
      extra_package_infos: upSalePackageIds,
    });
  },
};
