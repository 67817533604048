import { useCallback } from 'react';

import { TrackingApi } from 'api/TrackingApi';

export enum ConfirmationPopupName {
  SendVerification = 'send_verification_email',
  ChangeEmail = 'change_email',
  ConfirmationProfilePopup = 'confirmation_profile_popup',
  ConfirmationNotification = 'confirmation_notification',
  ConfirmationBanner = 'confirmation_banner',
  PresentsStatusBanner = 'presents_status',
  ConfirmationAfterRegPopup = 'confirmation_after_reg',
}

export enum ConfirmationPopupOperation {
  Show = 'show',
  Close = 'close',
  ClickClose = 'click_close',
  ClickChange = 'click_change',
  ClickAccept = 'click_accept',
  ClickOpen = 'click_open',
}

export const useEmailConfirmationPopupAnalytics = () => {
  const trackEmailConfirmationPopup = useCallback(
    ({
      popupName,
      operation,
    }: {
      popupName: ConfirmationPopupName;
      operation: ConfirmationPopupOperation;
    }) => {
      TrackingApi.trackPopup({
        name: popupName,
        operation,
        url: window.location.href,
      }).catch();
    },
    []
  );

  return {
    trackEmailConfirmationPopup,
  };
};
