import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MediaType } from 'types/enums/MediaType';
import { UserGender } from 'types/enums/UserGender';
import { PresentCategory } from 'types/interfaces/presents/PresentCategory';
import { PresentTrackInfo } from 'types/interfaces/presents/PresentTrackInfo';
import { UserContact } from 'types/interfaces/user/UserContact';
import { UserProfile } from 'types/interfaces/user/UserProfile';

interface BaseUserProfileInfo {
  ulid_id: string;
  name?: string;
  photoUrl?: string | null;
  gender: UserGender;
}

interface ContactPresents {
  sent: PresentTrackInfo[];
  contact: UserContact;
}

interface ProfileState {
  profile: UserProfile;
  lastActiveUserProfileInfo: BaseUserProfileInfo | null;

  mediaAccesses: Record<
    string,
    {
      accesses: Record<MediaType, string[]>;
      accessesByMediaType: MediaType[];
    }
  >;
  isMediaAccessesLoading: boolean;

  presentsCategories: PresentCategory[];
  isPresentsCategoriesLoading: boolean;

  contactPresents: ContactPresents | null;
  isContactPresentsLoading: boolean;
}

const initialState: ProfileState = {
  profile: {
    ulid_id: '',
    name: '',
    lastname: '',
    date_of_birth: '',
    age: 25,
    gender: UserGender.Male,
    city: null,
    country: null,
    private_photos: [],
    public_photos: [],
    liked: false,
    is_online: false,
    is_popular: false,
    is_real_gift_available: false,
    can_view: false,
    details: null,
    real_gift_wishlist: null,
  },
  lastActiveUserProfileInfo: null,

  mediaAccesses: {},
  isMediaAccessesLoading: false,

  presentsCategories: [],
  isPresentsCategoriesLoading: true,

  contactPresents: null,
  isContactPresentsLoading: true,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<UserProfile>) {
      state.profile = action.payload;
    },
    updateProfile(state, action: PayloadAction<Partial<UserProfile>>) {
      state.profile = { ...state.profile, ...action.payload };
    },

    setLastActiveUserProfileInfo(
      state,
      action: PayloadAction<BaseUserProfileInfo>
    ) {
      state.lastActiveUserProfileInfo = action.payload;
    },

    setContactMediaAccesses(
      state,
      action: PayloadAction<{
        contactId: string;
        accesses: Record<MediaType, string[]>;
        accessesByMediaType: MediaType[];
      }>
    ) {
      const { contactId, accesses, accessesByMediaType } = action.payload;

      state.mediaAccesses[contactId] = {
        accesses,
        accessesByMediaType,
      };
    },

    updateMediaAccess(
      state,
      action: PayloadAction<{
        contactId: string;
        newMediaId: number;
        newMediaType: MediaType;
      }>
    ) {
      const { contactId, newMediaId, newMediaType } = action.payload;

      state.mediaAccesses[contactId] = {
        ...state.mediaAccesses[contactId],
        accesses: {
          ...state.mediaAccesses[contactId].accesses,
          [newMediaType]: [
            ...(state.mediaAccesses?.[contactId]?.accesses?.[newMediaType] ||
              []),
            String(newMediaId),
          ],
        },
      };
    },

    setIsMediaAccessesLoading(state, action: PayloadAction<boolean>) {
      state.isMediaAccessesLoading = action.payload;
    },

    setPresentsCategories(state, action: PayloadAction<PresentCategory[]>) {
      state.presentsCategories = action.payload;
    },
    setIsPresentsCategoriesLoading(state, action: PayloadAction<boolean>) {
      state.isPresentsCategoriesLoading = action.payload;
    },

    setContactPresents(state, action: PayloadAction<ContactPresents>) {
      state.contactPresents = action.payload;
    },
    setIsContactPresentsLoading(state, action: PayloadAction<boolean>) {
      state.isContactPresentsLoading = action.payload;
    },

    updateContactPresent(
      state,
      action: PayloadAction<Partial<PresentTrackInfo>>
    ) {
      const updatedPresent = action.payload;

      if (!state.contactPresents?.sent.length) return;

      state.contactPresents.sent = state.contactPresents.sent.map(
        (presentItem) => {
          if (presentItem.id === updatedPresent.id) {
            return { ...presentItem, ...updatedPresent };
          }

          return presentItem;
        }
      );
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setProfile,
  updateProfile,
  setContactMediaAccesses,
  updateMediaAccess,
  setIsMediaAccessesLoading,

  setLastActiveUserProfileInfo,

  setPresentsCategories,
  setIsPresentsCategoriesLoading,

  setContactPresents,
  setIsContactPresentsLoading,
  updateContactPresent,

  resetState,
} = profileSlice.actions;

export const profile = profileSlice.reducer;
