import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Funnel } from 'helpers/funnels/funnels';
import { PWA_POPUPS } from 'helpers/pwa/constants';

export enum SystemPopupTypes {
  LowBalance = 1,
  InitCredits = 2,
  MailConfirmAfterReg = 3,
  ReportUser = 4,
  PresentSent = 5,
  PWAOnMailConfirm = 6,
}

export interface LowBalancePopupParams {
  funnel: Funnel;
  name?: string;
  photoUrl?: string;
  url?: string;
  min_income?: number;
  a_gift_id?: number;
  type?: 'media' | 'mailRead';
}

export interface StarterPopupParams {
  username?: string;
}

export interface ReportUserPopupParams {
  userId: string;
}

export interface PresentSentPopupParams {
  contactName: string;
  presentSrc: string;
}

export interface PWAPopupParams {
  name: PWA_POPUPS;
  isWizard: boolean;
}

type SystemPopupParams =
  | LowBalancePopupParams
  | StarterPopupParams
  | ReportUserPopupParams
  | PresentSentPopupParams
  | PWAPopupParams;

interface SystemPopupState {
  type: SystemPopupTypes | null;
  params: SystemPopupParams | null;
}

const initialState: SystemPopupState = {
  type: null,
  params: null,
};

const systemPopupSlice = createSlice({
  name: 'systemPopup',
  initialState,
  reducers: {
    setActiveSystemPopup(
      state,
      action: PayloadAction<{
        type: SystemPopupTypes;
        params?: SystemPopupParams;
      }>
    ) {
      state.type = action.payload.type;
      state.params = action.payload.params || null;
    },

    closeSystemPopup(state) {
      state.type = null;
      state.params = null;
    },

    resetState() {
      return initialState;
    },
  },
});

export const { setActiveSystemPopup, closeSystemPopup, resetState } =
  systemPopupSlice.actions;

export const systemPopup = systemPopupSlice.reducer;
